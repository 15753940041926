<template>
  <div>
    <header class="fd-app-mode-ead-header">
      <div
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1 :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }">
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Minhas Ocorrências</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Minhas Ocorrências</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1 class="aluno_font_color">
                              Minhas <span>Ocorrências</span>
                            </h1>
                            <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section>
                            <!-- Conteúdo principal -->
                            <div class="card-body">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <select
                                    v-model="fastFiltro.nomeOcorrenciaEscolhido"
                                    class="form-control"
                                    @change="aplicaFiltro()"
                                  >
                                    <option value="">
                                      -- Selecione um tipo de ocorrência --
                                    </option>
                                    <option
                                      v-for="nome in fastFiltro.nomeOcorrencia"
                                      :key="nome"
                                      :value="nome"
                                    >
                                      {{ nome }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <select
                                    v-model="fastFiltro.anoOcorrenciaEscolhido"
                                    class="form-control"
                                    @change="aplicaFiltro()"
                                  >
                                    <option value="">
                                      -- Selecione o ano --
                                    </option>
                                    <option
                                      v-for="ano in fastFiltro.anoOcorrencia"
                                      :key="ano"
                                      :value="ano"
                                    >
                                      {{ ano }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <button
                                    class="btn btn-sm btn-primary "
                                    @click="limparFiltro()"
                                  >
                                    <small>Limpa Filtros</small>>
                                  </button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive mt-2">
                                  <table class="table table-sm">
                                    <thead class="thead-dark">
                                      <tr class="text-center">
                                        <th>
                                          <small class="font-weight-bold">Ocorrência</small>
                                        </th>
                                        <th>
                                          <small class="font-weight-bold">Data Ocorrência</small>
                                        </th>
                                        <th>
                                          <small class="font-weight-bold">Anexo Ocorrência</small>
                                        </th>
                                        <th>
                                          <small class="font-weight-bold">Ações</small>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastOcorrenciaLoading">
                                      <tr>
                                        <td
                                          colspan="5"
                                          class="text-center"
                                        >
                                          Carregando Ocorrências...
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else-if="fastOcorrenciaTotal.length">
                                      <tr
                                        v-for="ocorrencia in fastOcorrenciaPagination"
                                        :key="ocorrencia.id_registro_ocorrencia"
                                      >
                                        <td class="align-middle text-center">
                                          {{ ocorrencia.nome_ocorrencia }}
                                        </td>
                                        <td class="align-middle text-center">
                                          {{ formataDataT(ocorrencia.data_notificacao) }}
                                        </td> 
                                        <td class="align-middle text-center">
                                          <a
                                            v-if="ocorrencia.path_anexo"
                                            :href="ocorrencia.path_anexo"
                                            target="_blank"
                                          >Abrir anexo</a>
                                        </td>
                                        <td class="align-middle text-center">
                                          <button
                                            class="btn btn-sm btn-primary "
                                            @click="exibeModalDetalhesOcorrencia(ocorrencia)"
                                          >
                                            <small>Ver detalhes</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="5"
                                          class="text-center"
                                        >
                                          Nenhuma Ocorrência Cadastrado
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col-12 text-center mt-4">
                                  <Pagination
                                    :page-size="10"
                                    :items="fastOcorrenciaFiltro"
                                    @changePage="fastOcorrenciaPagination = $event"
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- /Conteúdo principal -->
                          </section>

                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- modal -->
    <modal
      name="exibeModalDetalhesOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Detalhes da Ocorrência</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalDetalhesOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="container  border">
                <div class="row border">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <small><b>Detalhe da Ocorrência:</b></small>
                    <br>
                    <small>{{ fastOcorrenciaDetalhes.detalhe_ocorrencia }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import muralAvisos from "../components/MuralAvisos";
import Pagination from "../components/Pagination";

export default {
  // Nome do componente
  name: "HomeInternoAlunoOcorrencias",
  // Componentes
  components: {
    muralAvisos,
    Pagination
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
       modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTemplate: settings.fastTemplate,
      fastOcorrenciaTotal: [],
      fastOcorrenciaFiltro: [],
      fastOcorrenciaPagination: [],
      fastOcorrenciaLoading: true,    
      fastOcorrenciaDetalhes: {
        aceita_anexo: "",
        data_notificacao: null,
        data_registro: "",
        detalhe_ocorrencia: "",
        id_pessoa_aluno: 0,
        id_registro_ocorrencia: 0,
        id_tipo_ocorrencia: 0,
        id_usuario_registro: null,
        nome_ocorrencia: "",
        notifica_responsavel: false,
        obs: "",
        path_anexo: "",
        responsavel_notificado: "",
      },
      fastFiltro: {
        nomeOcorrencia: [],
        nomeOcorrenciaEscolhido: "",
        anoOcorrencia: [],
        anoOcorrenciaEscolhido: ""
      }  
      
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
        // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
            // Iniciar aqui
            this.getOcorrencia(this.$route.params.id_plataforma)
            this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
      async getOcorrencia(id_pessoa_aluno) {
          this.promiseGetFastApi("api/fast_secretaria_ocorrencia/lista", "id_pessoa_aluno=" + this.$store.state.fastUsuario.Id_pessoa) 
          .then(o => { 
            console.log(o)
              this.fastOcorrenciaTotal = o
              this.fastOcorrenciaFiltro = o
              this.fastOcorrenciaLoading = false

              // Retorna os nomes de ocorrência e elimina duplicados
              this.fastFiltro.nomeOcorrencia = Array.from(new Set(o.map(ocorrencia => { return ocorrencia.nome_ocorrencia }))) 

              let anos = o.map(ocorrencia => { 
                if (ocorrencia.data_notificacao) {
                  return ocorrencia.data_notificacao.substring(0, 4)
                }
              })
              this.fastFiltro.anoOcorrencia = Array.from(new Set(anos))

          })
          .catch(e => {
            this.fastOcorrenciaLoading = false
            console.log(e)
          })
      },
      exibeModalDetalhesOcorrencia(ocorrencia){
        console.log("exibeModalDetalhesOcorrencia", ocorrencia)
        this.fastOcorrenciaDetalhes = ocorrencia
        this.showModal("exibeModalDetalhesOcorrencia")
      },
      aplicaFiltro(){
        if (this.fastFiltro.nomeOcorrenciaEscolhido) {
          this.fastOcorrenciaFiltro = this.fastOcorrenciaTotal.filter(o => {
            return (this.fastFiltro.nomeOcorrenciaEscolhido == o.nome_ocorrencia)
          })
        } else {
          this.fastOcorrenciaFiltro = this.fastOcorrenciaTotal
        }

        if (this.fastFiltro.anoOcorrenciaEscolhido) {
          this.fastOcorrenciaFiltro = this.fastOcorrenciaFiltro.filter(o => {
            return (this.fastFiltro.anoOcorrenciaEscolhido == o.data_notificacao.substring(0, 4))
          })
        }
      },
      limparFiltro(){
        this.fastFiltro.nomeOcorrenciaEscolhido= ""
        this.fastFiltro.anoOcorrenciaEscolhido= ""
        this.aplicaFiltro()
      }
  },
};
</script>

<style scope>

</style>
